import styled from "styled-components";

export const BlogRoll = styled.div`
  max-width: 792px;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
`;
