import React from "react";
import { PageProps } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/Layout";
import BlogRoll from "../../components/cms/BlogRoll";
import PageWrapper from "../../components/layout/PageWrapper";
import PageTitle from "../../components/layout/PageTitle";

const BlogPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <Helmet bodyAttributes={{"type": "blog" }} />
    <PageWrapper align="left">
      <PageTitle heading="Blog" align="left"/>
      <BlogRoll />
    </PageWrapper>
  </Layout>
);

export default BlogPage;