import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Agnieszka from "../../img/agnieszka-czyzewska.png";
import Doctor from "../../img/doctor.png";

const size = "48px";

const Container = styled.div`
  display: flex;
  min-height: ${size};
  align-items: center;
`;

const Avatar = styled.div`
  width: ${size};
  height: ${size};
  position: relative;
  overflow:hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
  }
`;

const AuthorDetails = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;  

  p {
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.colors.black};
    margin: 0;
  }

  span {
    font-size: 12px;
    color: ${props => props.theme.colors.black};
  }

  p, span {
    line-height: 180%;
  }
`;


const getImage = (author: string) => {
  return author.indexOf("Agnieszka") !== -1 ? Agnieszka : Doctor;
}

type Props = {
  author?: string;
  authorsTitle?: string;
}

const AuthorHeader = ({
  author = "",
  authorsTitle = "",
}: Props) => {
  const [image, setImage] = useState<string>();

  if (!author) {
    return <></>;
  }

  useEffect(() => {
    setImage(getImage(author));
  }, [author]);

  return (
    <Container>
      <Avatar>
        <img src={image} alt={author} />
      </Avatar>
      <AuthorDetails>
        <p>{author}</p>
        {authorsTitle && <span>{authorsTitle}</span>}
      </AuthorDetails>
    </Container>
  )
};

export default AuthorHeader;
