import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  &:first-child {
    padding-top: 16px;
  }

  &:last-child {
    border-bottom: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 32px 0;

    &:first-child {
      padding-top: 0;
    }
  }
  a:hover h2 {
    color: ${props => props.theme.colors.primary};
  }

  h2 {
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 24px;
    }
  }

  header span {
    font-size: 14px;
    color: ${props => props.theme.colors.gray1};
    display: block;
  }
`;

export const ArticleDescription = styled.p`
  margin: 25px 0;
`;

export const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.black};

  &:hover {
    opacity: 1;
  }
`;
