import React from "react";
import { graphql, StaticQuery } from "gatsby";
import * as S from "./BlogRoll.styled";
import BlogPostTile from "./BlogPostTile";

type Props = {
  data: {
    allMdx: {
      edges: Array<{
        node: {
          slug: string;
          frontmatter: {
            author?: string;
            authorsTitle?: string;
            date?: string;
            description?: string;
            readingTime?: number;
            title?: string;
            templateKey?: string;
          },
        },
      }>
    };
  };
  count: number;
}

const BlogRoll = (props: Props) => {
  const { edges: posts } = props.data.allMdx;

  return (
    <S.BlogRoll>
      {posts && posts.map(({ node: post }) => (
        <BlogPostTile
          key={post.slug}
          to={"/" + post.slug}
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          description={post.frontmatter.description}
          author={post.frontmatter.author}
          authorsTitle={post.frontmatter.authorsTitle}
        />
      ))}
    </S.BlogRoll>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              slug
              frontmatter {
                title
                author
                authorsTitle
                description
                templateKey
                date(formatString: "DD.MM.YYYY, hh:mm")
                readingTime
              }
            }
          }
        }
      }
    `}
    // @ts-expect-error
    render={(data: Data, count: number) => <BlogRoll data={data} count={count} />}
  />
);
