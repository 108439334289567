import React from "react";  
import AuthorHeader from "./AuthorHeader";
import * as S from "./BlogPostTile.styled";


type Props = {
  to: string;
  author?: string;
  authorsTitle?: string;
  date?: string;
  description?: string;
  readingTime?: number;
  title?: string;
  templateKey?: string;
}

const BlogPostTile = ({
  to,
  title,
  date,
  description,
  author,
  authorsTitle
}: Props) => (
  <S.Article>
    <header>
      <S.Link
        to={to}
        className="blog-roll-item"
      >
        <h2>{title}</h2>
      </S.Link>
      <span>{date}</span>
    </header>
    <S.ArticleDescription>
      {description}
    </S.ArticleDescription>
    <AuthorHeader
      author={author}
      authorsTitle={authorsTitle}
    />
  </S.Article>
);

export default BlogPostTile;
